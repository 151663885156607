
//Font Scheme...
@font-face {
    font-family: marquisette;
    src: url(../font/marquisette.ttf);
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

//Color Scheme....
$primary_color: #006A5F;
$background_color:#FFFEF8;
$secondary_color:#707070;

@keyframes scroll {
    to{
        transform: translate(calc(-50% - .45rem));
    }
}

@keyframes dash {
    to {
        stroke-dashoffset: 0;
      }
}

@keyframes dash2 {
    to {
        stroke-dashoffset: 0;
      }
}

@keyframes scroll-watcher {
    to {
        scale: 1 1;
    }
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
} 


